<template>
  <canvas id="myChart" width="500" height="500"></canvas>
</template>

<script>
import {
  Chart,
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Legend,
  CategoryScale,
} from 'chart.js'

Chart.register(
  LineController,
  LineElement,
  PointElement,
  LinearScale,
  Legend,
  Title,
  CategoryScale
)

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    rawData: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  mounted() {
    const ctx = document.getElementById('myChart')

    const datasets = []

    // Get subjects
    const subjects = Array.from(
      new Set(this.rawData.flatMap((item) => Object.keys(item['data'])))
    ).sort()

    // Set line colors
    const colors = ['#0075cb', '#ff9800', '#00ca00']

    // Get result data for subject scores
    this.rawData.forEach((item, index) => {
      let set = {
        label: item.name,
        fill: false,
        spanGaps: true,
        data: [],
        borderColor: colors[index],
        tension: 0.2,
        pointRadius: 5,
      }

      subjects.forEach((subject) => {
        set['data'].push(parseInt(item.data[subject]))
      })

      datasets.push(set)
    })

    new Chart(ctx, {
      type: 'line',
      data: {
        labels: subjects,
        datasets: datasets,
      },
      options: {
        plugins: {
          title: {
            display: true,
            text: 'Termly Performance',
          },
          legend: {
            position: 'top',
          },
        },
      },
    })
  },
}
</script>
