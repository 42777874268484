<template>
  <div class="vueditor">
    <viewer :raw-data="rawData" />
  </div>
</template>

<script>
import viewer from './components/viewer'

export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {}
      },
    },
    rawData: Array,
  },
  components: {
    viewer,
  },
}
</script>
